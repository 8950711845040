export { purchaseClass };

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";
class purchaseClass {
  constructor() {
		eventBus.$on("listGateway", () => {
      this.listPaymentGateways();
    });

    eventBus.$on("paymentInitiation", data => {
      this.paymentIntiation(data);
    });

    eventBus.$on("paymentDetails", data => {
      this.getPaymentDetails(data);
    });

    eventBus.$on("paymentConfirmation", data => {
      this.checkPaymentConfirmation(data);
    });

    eventBus.$on("create-free-purchase", data => {
      this.createPurchase(data);
    });

    eventBus.$on("telcostcbh", data => {
      this.telcostcbh(data);
    })
  }

  listPaymentGateways() {
    let payload = { paypalsub: "YES" };
    store
      .dispatch("listPaymentGateways", payload)
      .then(response => {
        if (response.data) {
          eventBus.$emit("listgateway-response", response.data);
        }
      })
      .catch(error => console.log(error));
  }

  checkPaymentConfirmation(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("paymentConfirmation", payload)
      .then(response => {
        eventBus.$emit("loading", false);
        console.log("response", response);
        if (response.data) {
          eventBus.$emit("paymentconfirmation-response", response.data);
        }
      })
      .catch(error => console.log(error));
  }

  getPaymentDetails(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("paymentDetail", payload)
      .then(response => {
        eventBus.$emit("loading", false);
        if (response.data) {
          eventBus.$emit("paymentDetail-response", response.data);
        }
      })
      .catch(error => console.log(error));
  }

  createPurchase(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("purchaseCreate", payload)
      .then(response => {
        eventBus.$emit("loading", false);
        if (response.data) {
          eventBus.$emit("free-purchase-response", response.data);
        }
      })
      .catch(error => console.log(error));
  }

  telcostcbh(payload) {
    store.dispatch("telcoSTCBH", payload)
      .then(response => {
        if (response.data) {
          eventBus.$emit("telcostcbh-response", response.data);
        }
      }).catch(error => console.log(error));
  }
}
